<template>
  <HpAnchor
    class="action-link"
    v-if="isCompared"
    @click.prevent="removePlan"
    :id="`remove-compared-plan-${medicareId}`"
  >
    <HpIcon name="bookmark-filled" />
    Compared
  </HpAnchor>
  <HpAnchor class="action-link" v-else @click.prevent="addPlan" :id="`compare-plan-${medicareId}`">
    <HpIcon name="bookmark" />
    <span class="hidden xs:flex">Add to Compare</span>
    <span class="flex xs:hidden">Compare</span>
  </HpAnchor>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'

  const props = defineProps<{
    medicareId: string
    eventData: (() => Record<string, any>) | Record<string, any> | null | undefined
  }>()

  const session = SessionStore.use()

  const isCompared = computed(() => session.comparedPlans.includes(props.medicareId))

  const addPlan = () => {
    session.comparedPlans.push(props.medicareId)
    // SessionStore.save()
  }

  const removePlan = () => {
    session.comparedPlans = _without(session.comparedPlans, props.medicareId)
    // SessionStore.save()
  }
</script>

<style scoped></style>

<style>
  input[type='checkbox'] {
    cursor: pointer;
  }
</style>
