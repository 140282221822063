<script setup lang="ts">
  import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { RxStore } from '~/stores/rx'
  import { PlanBenefitCostType } from '~/generated/api-clients-generated'

  const props = defineProps<{
    quote: QuotedPlanVm
    emptyState: boolean
    id: string
  }>()

  const { getComponentContent } = Cms.use()
  const rx = RxStore.use()
  const { maybeDo } = ActionGuard.use()
  const { show } = DialogController.use()

  const { content } = await getComponentContent('QuoteCardCostEssentialsSidebar')

  const { track } = Analytics.use()

  const onSidebarShown = () => {
    track('CostEssentialsSidebarShown', {
      planId: props.quote.medicareId
    })
  }

  const costs = computed(() => [
    {
      id: 'monthlyPremium',
      tooltip: 'PlanPremiums',
      label: 'Monthly Premium',
      value: props.quote.details.premium
    },
    {
      id: 'maxOop',
      tooltip: 'MaxOutOfPocket',
      label: 'Max out-of-pocket',
      value: props.quote.details.maxOutOfPocket
    },
    {
      id: 'yearlyDeductible',
      tooltip: 'MedicalDeductible',
      label: 'Yearly Deductible',
      value: props.quote.details.combinedDeductible
    },
    {
      id: 'primaryCareCopay',
      tooltip: 'PrimaryCareVisit',
      label: 'Primary Care visit copay',
      value: props.quote.details.providerBenefits?.doctorCopay?.amount,
      type: props.quote.details.providerBenefits?.doctorCopay?.amountType
    },
    {
      id: 'specialistCopay',
      tooltip: 'SpecialistVisit',
      label: 'Specialist visit copay',
      value: props.quote.details.providerBenefits?.specialistCopay?.amount,
      type: props.quote.details.providerBenefits?.specialistCopay?.amountType
    },
    {
      id: 'estimatedYearlyDrugCosts',
      tooltip: 'EstimatedDrugCosts',
      label: 'Estimated yearly drug costs',
      value: props.quote.drugCost
    },
    {
      id: 'expectedAllInCosts',
      tooltip: 'OutOfPocketMedicalCosts',
      label: 'Estimated Out-of-Pocket Costs',
      value: props.quote.recommendation.medicalCost
    }
  ])
</script>

<template>
  <HpAnchor class="action-link" v-dialog-target:[id] @click.prevent>See more</HpAnchor>
  <HpSidebar :title="content.ext.title" :icon="content.ext.icon" :id="id" @show="onSidebarShown">
    <aside>
      <section class="flex w-full flex-col py-4">
        <dl class="flex flex-col divide-y divide-border-light border-t border-border-light">
          <div
            class="flex w-full items-center divide-x divide-border-light"
            v-for="cost in costs.filter((x) => !_isNil(x.value))"
          >
            <dt class="w-[60%] px-2 py-2">
              <PxTooltip :text="cost.label" :name="cost.tooltip" class="text-primary-900" />
            </dt>
            <dl class="w-[40%] p-4 text-right text-2xl font-bold text-primary-900">
              <template v-if="rx.rxs.length < 1 && cost.id === 'estimatedYearlyDrugCosts'">
                <HpAnchor
                  class="flex !items-center no-underline"
                  @click.prevent="() => maybeDo(() => show('update-drugs-sidebar'))"
                  :event-data="
                    () => ({
                      openedFrom: 'CostEssentialsSidebar'
                    })
                  "
                >
                  <HpIcon name="add" class="scale-[75%]" />
                  <span>Add Drugs</span>
                </HpAnchor>
              </template>
              <template v-else-if="emptyState && cost.id === 'expectedAllInCosts'">
                <HpAnchor
                  class="flex !items-center no-underline"
                  @click.prevent="() => maybeDo(show('update-insurance-sidebar'))"
                  :event-data="
                    () => ({
                      openedFrom: 'CostEssentialsSidebar'
                    })
                  "
                >
                  <HpIcon name="add" class="scale-[75%]" />
                  <span>Add Details</span>
                </HpAnchor>
              </template>
              <template v-else-if="!!cost.type">{{
                formatBenefit({ amount: cost.value, amountType: cost.type })
              }}</template>
              <template v-else>{{ _isNil(cost.value) ? 'N/A' : $formatUSD(cost.value) }}</template>
            </dl>
          </div>
        </dl>
      </section>
    </aside>
  </HpSidebar>
</template>

<style scoped></style>
