<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

  defineProps<{
    quote: QuotedPlanVm
  }>()

  const { getQuoteCardEventProps } = QuoteAnalytics.use()
  const { push } = AppRouter.use()
</script>

<template>
  <div class="quote-actions">
    <PxApplyButton
      :medicare-id="quote.medicareId"
      :plan-type="quote.type"
      :plan-year="quote.details.year!"
      :event-data="getQuoteCardEventProps('apply', quote)"
    />
    <HpAnchor class="font-semibold" @click.prevent="push(`/details/${quote.medicareId}`)"
    >See Plan Details
    </HpAnchor>
  </div>
</template>

<style scoped>
  .quote-actions {
    @apply flex flex-col items-center space-y-4;
  }
</style>