import { EngineStore } from '~/stores/engine'
import { PlanType } from '~/generated/api-clients-generated'
import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export interface PlanBannerConfig {
  color?: string | null | undefined
  icon?: string | null | undefined
  priority?: number | null | undefined
}

export class PlanBanners {
  static async use(medicareId: string) {
    const { getComponentContent } = Cms.use()
    const { content } = await getComponentContent('PlanBanners', '2')

    const { flag } = FeatureFlags.use()
    const { hydratePlaceholders } = Cms.use()

    const config = computed(
      () => flag<Record<string, PlanBannerConfig>>('plan-banner-config').value
    )

    const engine = EngineStore.use()

    const quote = computed(() => engine.quotesCatalog[medicareId])

    const currentKey = computed(() => {
      let key = ''
      if (engine.isEmptyState) key += 'empty_'
      if (quote.value.type === PlanType.MAPD) key += 'mapd_'
      else if (quote.value.type === PlanType.PDP) key += 'pdp_'

      return key
    })

    const availableBanners = computed(() => Object.keys(content.value.ext)
      .filter(k => k.startsWith(currentKey.value))
      .map(k => {
        const configKey = Object.keys(config.value).find(x => k.endsWith(x))
        const bannerConf = config.value?.[configKey ?? 'none']

        return {
          key: k,
          attribute: _camelCase(k.replace(currentKey.value, '')),
          color: bannerConf?.color,
          icon: bannerConf?.icon,
          priority: +(bannerConf?.priority ?? 0),
          text: hydratePlaceholders(content.value.ext?.[k], {
            carrierName: quote.value.details.carrierName
          })
        }
      })
    )

    const banner = computed(() => _sortBy(availableBanners.value
      .filter(x => quote.value.planAttributes.includes(x.attribute)),
        ab => -ab.priority)?.[0])

    return {
      banner,
      availableBanners,
      currentKey
    }
  }
}
