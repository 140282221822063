<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

  defineProps<{
    quote: QuotedPlanVm
  }>()

  const { getQuoteCardEventProps } = QuoteAnalytics.use()
</script>

<template>
  <PxComparePlanControl
    :medicare-id="quote.medicareId"
    :event-data="getQuoteCardEventProps('add-to-compare', quote)"
  />
  <PxSavedPlanControl
    :medicare-id="quote.medicareId"
    :year="quote.details.year!"
    :event-data="getQuoteCardEventProps('save-this-plan', quote)"
  />
</template>

<style scoped>

</style>