<script setup lang="ts">
  const props = defineProps<{
    id: string
    icon: string
    label: string
    details: Array
  }>()

  const { show } = DialogController.use()
</script>

<template>
  <HpSidebar :id="id" :title="label" :icon="icon">
    <aside class="benefit-sidebar-outer">
      <section v-for="item in details" class="benefit-category">
        <h2 class="category-header">{{ item.name }}</h2>
        <p class="category-description" v-html="item.description"></p>
      </section>
    </aside>
  </HpSidebar>
</template>

<style scoped>
  .benefit-sidebar-outer {
    @apply mt-2 flex w-full flex-col gap-4;

    .benefit-category {
      @apply flex w-full flex-col gap-1;

      .category-header {
        @apply text-lg font-bold;
      }

      .category-description {
      }
    }
  }
</style>
