<template>
  <HpAnchor class="text-center font-semibold no-underline" @click.prevent="show(id)">
    <HpIcon class="-mt-1 mr-2 inline" :name="content.ext.icon" />
    <span>{{ content.ext.launcher_button_text }}</span>
  </HpAnchor>
  <HpSidebar :id="id" :title="content.ext.title" icon="piggy-bank">
    <aside>
      <section class="mb-4 flex w-full flex-col">
        <dl class="mb-2 flex flex-col border-t border-border-light px-2">
          <div
            :class="[
              'flex',
              'items-center',
              'w-full',
              { 'border-b border-border-light': idx !== displayedCosts.length - 1 }
            ]"
            v-for="(cost, idx) in displayedCosts"
          >
            <dt class="flex w-[62%] flex-col border-r border-border-light py-4">
              <PxTooltip class="text-primary-900" :name="cost.tooltip" :text="cost.title" />
              <span class="text-base text-gray-800">{{ cost.subtitle }}</span>
            </dt>
            <dd class="ml-auto text-2xl font-bold text-primary-900">
              <template v-if="rx.rxs.length < 1 && cost.key === 'drug_costs'">
                <HpAnchor
                  class="flex no-underline"
                  @click.prevent="() => maybeDo(() => show('update-drugs-sidebar'))"
                >
                  <HpIcon name="add" class="scale-[75%]" />
                  <span>Add Drugs</span>
                </HpAnchor>
              </template>
              <template v-else-if="emptyState && cost.key === 'oop'">
                <HpAnchor class="flex no-underline" @click.prevent="() => maybeDo(() => show('update-insurance-sidebar'))">
                  <HpIcon name="add" class="scale-[75%]" />
                  <span>Add Details</span>
                </HpAnchor>
              </template>
              <template v-else>{{ $formatUSD(cost.value) }}</template>
            </dd>
          </div>
        </dl>
        <div class="flex w-full justify-between rounded-xl bg-primary-100 px-2 py-2">
          <div class="w-[60%] text-2xl font-bold text-primary-900">Total</div>
          <div class="ml-auto text-2xl font-bold text-primary-900">
            {{ $formatUSD(quote.getYearlyEstCost(!!emptyState ? configuredCosts.filter(cost => cost !== 'oop') : configuredCosts)) }}
          </div>
        </div>
      </section>
      <section class="py-8">
        <HpButton
          variant="none"
          class="py-3 font-semibold shadow-card"
          @click.prevent="() => maybeDo(() => show(`${props.id}-current-plan-compare-sidebar}`))"
          ><span class="flex items-center gap-2 px-3"
            ><HpIcon name="arrows-left-right-offset" class="text-primary" /><span
              >Compare this plan against my current plan</span
            ></span
          ></HpButton
        >
      </section>
      <footer class="flex flex-col space-y-4" v-if="quote.type === PlanType.MAPD">
        <p class="text-base"><sup>1</sup> {{ content.ext.part_b_premiums_footnote }}</p>
        <p class="text-base"><sup>2</sup> {{ content.ext.out_of_pocket_footnote }}</p>
      </footer>
    </aside>
  </HpSidebar>
  <HpSidebar :id="`${id}-current-plan-compare-sidebar}`">
    <div class="mt-8">
      <CurrentPlanStateMachine
        config="sidebar"
        :original-quote="quote"
        :id="`${id}-current-plan-compare-sidebar}`"
      />
    </div>

    <template #footer>
      <!--      buttons get teleported here from the state machine components-->
      <div></div>
    </template>
  </HpSidebar>
</template>

<script lang="ts" setup>
  import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { RxStore } from '~/stores/rx'
  import CurrentPlanStateMachine from '~/components/currentPlan/CurrentPlanStateMachine.vue'
  import { PlanType } from '~/generated/api-clients-generated'

  const props = defineProps<{
    quote: QuotedPlanVm
    emptyState: boolean
    id: string,
    version?: string | null | undefined
  }>()

  const { getComponentContent } = Cms.use()
  const { flag } = FeatureFlags.use()

  const { $formatUSD } = useNuxtApp()

  const { content } = await getComponentContent('QuoteCardCostsSidebar', props.version ?? null)

  const configuredCosts = computed(() => flag<string[]>('quote-cost-elements').value)

  const rx = RxStore.use()

  const { maybeDo } = ActionGuard.use()
  const { show, hide } = DialogController.use()

  const costs = computed(() => [
    {
      key: 'plan_premiums',
      title: 'Plan Premiums',
      subtitle: `${$formatUSD(props.quote.details.premium)} x 12`,
      value: props.quote.details.premium * 12,
      tooltip: 'PlanPremiums',
      planTypes: [PlanType.MAPD, PlanType.PDP]
    },
    {
      key: 'drug_costs',
      title: 'Drug Costs',
      subtitle: `Based on your drug list`,
      value: props.quote.drugCost,
      tooltip: 'EstimatedDrugCosts',
      planTypes: [PlanType.MAPD, PlanType.PDP]
    },
    {
      key: 'part_b_premium',
      title: `Standard Part B Premiums <sup>1</sup>`,
      subtitle: `${$formatUSD(props.quote.recommendation.partBPremium)} x 12`,
      value: props.quote.recommendation.exists
        ? props.quote.recommendation.partBPremium * 12
        : null,
      tooltip: 'PartBPremiums',
      planTypes: [PlanType.MAPD]
    },
    {
      key: 'part_b_reduction',
      title: 'Part B Premium Reduction',
      subtitle: `${props.quote.recommendation.partBReduction > 0 ? '-' : ''}${$formatUSD(
        props.quote.recommendation.partBReduction
      )} x 12`,
      value: props.quote.recommendation.exists
        ? props.quote.recommendation.partBReduction === 0
          ? props.quote.recommendation.partBReduction
          : -(props.quote.recommendation.partBReduction * 12)
        : null,
      tooltip: 'PartBPremiumReduction',
      planTypes: [PlanType.MAPD]
    },
    {
      key: 'oop',
      title: `Estimated Out-of-Pocket Costs <sup>2</sup>`,
      subtitle: `Based on your healthcare usage`,
      value: props.quote.recommendation.medicalCost,
      tooltip: 'OutOfPocketMedicalCosts',
      planTypes: [PlanType.MAPD]
    }
  ])

  const displayedCosts = computed(() =>
    costs.value.filter(
      (c) =>
        c.planTypes.includes(props.quote.type) &&
        configuredCosts.value.includes(c.key) &&
        !_isNil(c.value)
    )
  )
</script>

<style scoped></style>
